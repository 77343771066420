

function App() {
  return (
    <div className="App">
      <h1>Hello World From Reealtech Temp</h1>
    </div>
  );
}

export default App;



